import { Avatar, Button, Form } from "antd"
import { AppContext } from "components/AppContext"
import CPInput from "components/CP/CPInput"
import LinkPreview from "components/Editor/LinkPreview"
import AddMention from "components/Editor/Mention"
import ReferenceCard from "components/Feed/Posts/ReferenceCard"
import { useContext } from "react"
import { MobileView } from "react-device-detect"
import { useSelector } from "react-redux"
import { addPostState } from "redux/slices/addPostSlice"
import { profileState } from "redux/slices/profileReducer"

export default function AddPostContentForm({
  form,
  files,
  editMode,
  settings,
  onSubmit,
  description,
  setDescription,
  setTitle,
  title,
  loading,
  children,
  noAttachment,
}) {
  const { userInfo } = useSelector(profileState)
  const { referencePost } = useSelector(addPostState)
  const referenceId = referencePost?.reference_post_id || referencePost?.id
  const { companyDetails = null } = useContext(AppContext) || {}

  return (
    <Form
      form={form}
      layout="horizontal"
      className="overflow-auto p-5 pt-4"
      style={{ maxHeight: MobileView ? 400 : 600 }}
    >
      <div className="mh-212">
        <div className="flex items-center">
          <Avatar
            size={75}
            className="bg-white border border-gray-300 flex-shrink-0"
            src={
              companyDetails?.img ||
              userInfo?.icon ||
              "/assets/onBoarding/profile_avatar.jpg"
            }
          />

          <div className="flex flex-col ml-4">
            {companyDetails ? (
              <div>
                <span className="px-1 f-16 font-medium">
                  {companyDetails?.company_name}
                </span>
                <span className="px-1 f-12 text-gray-600 font-medium">
                  ({userInfo?.first_name} {userInfo?.last_name})
                </span>
              </div>
            ) : (
              <span className="px-1 f-16 font-medium">
                {userInfo?.first_name} {userInfo?.last_name}
              </span>
            )}
            <div className="flex flex-wrap">{settings}</div>
          </div>
        </div>

        <CPInput
          className="mb-3 mt-4"
          name="title"
          type="text"
          maxLength={250}
          placeholder="Enter a title"
          onChange={event => setTitle(event.target.value)}
          value={title}
        />

        <AddMention
          value={description}
          onChange={setDescription}
          className="w-full border rounded-lg"
          placeholder={
            companyDetails
              ? "Write your mind with company profile..."
              : `What's on your mind, ${userInfo?.first_name}?`
          }
        />

        {files}
      </div>

      {referencePost && referenceId && (
        <div className="overflow-auto my-4 rounded-lg">
          <ReferenceCard
            previewMode
            showReference={false}
            expandable={false}
            reference={referencePost}
            postId={referencePost?.id}
            referenceId={referenceId}
          />
        </div>
      )}

      {description && String(description)?.search("<iframe") >= 0 && (
        <div className="bg-gray-200 text-center text-gray-500 f-14 p-3 mt-3 rounded-lg">
          An iframe will be automatically loaded in your post.
        </div>
      )}

      <LinkPreview
        body={description}
        className="border-gray-300 bg-gray-100 border-solid border mt-3"
        closable
      />

      <div className="flex justify-between items-center mt-3">
        <span className="flex items-center">
          <Button
            type="link"
            htmlType="button"
            className="text-black px-1 py-0 bg-transparent"
            onClick={() => setDescription(description + "#")}
          >
            <span className="mr-2">+</span>
            <span>Hashtag</span>
          </Button>

          {children}
        </span>

        <Button
          disabled={!(title || description) && noAttachment}
          htmlType="submit"
          type="primary"
          className="px-8"
          loading={loading}
          onClick={onSubmit}
        >
          {editMode ? "Save" : "Post"}
        </Button>
      </div>
    </Form>
  )
}
