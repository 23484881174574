import { CloseOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import CPCascader from "components/CP/CPCascader"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getProductPropertiesList,
  productPropertiesState,
} from "redux/slices/productPropertiesSlice"

function PropertiesDropdownField({
  handleRemove,
  addNewItem = true,
  ...props
}) {
  const dispatch = useDispatch()
  const dataInRedux = useSelector(productPropertiesState)

  useEffect(() => {
    if (!dataInRedux?.data) {
      dispatch(getProductPropertiesList())
    }
  }, [])

  const renderSelectedValues = () => {
    return props?.selectedValues?.map((item, index) => {
      const found = dataInRedux?.data?.find(c => c.id === item)
      return (
        <Tag
          key="Tag"
          closeIcon={<CloseOutlined />}
          closable={handleRemove}
          onClose={e => {
            e.preventDefault()
            handleRemove(item, index)
          }}
          className="text-xs rounded-lg py-1 border-gray-300 mb-1 mr-1 inline-flex items-center"
        >
          {found?.label || item}
        </Tag>
      )
    })
  }

  return (
    <>
      <CPCascader
        {...props}
        search={addNewItem}
        className="w-full"
        disabled={dataInRedux?.loading}
        loading={false}
        data={dataInRedux?.data?.map(item => ({
          label: item?.label,
          value: item?.id,
        }))}
      >
        {props?.children || null}
      </CPCascader>

      {props?.selectedValues && (
        <div className="mt-2">{renderSelectedValues()}</div>
      )}
    </>
  )
}

export default PropertiesDropdownField
