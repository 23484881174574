import { Space, Typography } from "antd"
import { AppContext } from "components/AppContext"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useContext, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { setReference } from "redux/slices/addPostSlice"
import { profileState } from "redux/slices/profileReducer"
import apiService from "services/apiService"

const { Text } = Typography

const ActionsCard = ({
  item,
  type,
  allowReply,
  toggleEditModal,
  toggleReplies,
  setInstance,
} = {}) => {
  const { checkSubscription } = useSubscriptionContext()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)
  const { companyDetails = null } = useContext(AppContext) || {}
  const { userInfo } = useSelector(profileState)

  const handleLike = async () => {
    if (loading) {
      return
    }

    try {
      setLoading(true)
      const url =
        type === "post"
          ? `/api/network/post/${item.id}/like`
          : `/api/network/comment/${item.id}/like`

      if (item.is_liked) {
        const res = await apiService.delete(url)
        if (res?.data?.code === 200) {
          setInstance({
            ...item,
            likes: Number(item?.likes || 1) - 1,
            is_liked: false,
          })
        }
      } else {
        const res = await apiService.post(url)
        if (res?.data?.code === 200) {
          setInstance({
            ...item,
            likes: Number(item?.likes || 0) + 1,
            is_liked: true,
          })
        }
      }
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  if (!userInfo) {
    return null
  }

  return (
    <Space className="f-27" size={20}>
      <Space onClick={handleLike}>
        {item?.is_liked ? (
          <span
            className={`icon-ic_favorite_24px f-19 ant-typography-error cursor-pointer ${
              loading ? "text-gray-500" : ""
            }`}
          />
        ) : (
          <span
            className={`icon-ic_favorite_border_24px f-19 cursor-pointer ${
              loading ? "text-gray-500" : ""
            }`}
          />
        )}
        <Text className="f-16 block">{item?.likes || 0}</Text>
      </Space>

      {allowReply && (
        <Space onClick={toggleReplies}>
          <span className="icon-ic_chat_bubble_outline_24px f-19 cursor-pointer" />
          <Text className="f-16 block">{item?.comments || 0}</Text>
        </Space>
      )}

      {type === "post" && (
        <span
          className="icon-ic_near_me2_24px f-19 cursor-pointer"
          onClick={() => {
            dispatch(
              setReference({ ...item, companyId: companyDetails?.profile_id })
            )
            if (toggleEditModal) {
              toggleEditModal(true)
            }
          }}
        />
      )}
    </Space>
  )
}

export default ActionsCard
