import { Avatar, Mentions } from "antd"
import { useState } from "react"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

export default function AddMention({
  className = "",
  value,
  placeholder,
  onChange,
  disabled,
  rows = 6,
  autoSize = false,
}) {
  const [timer, setTimer] = useState(null)
  const [loading, setLoading] = useState(false)
  const [data, setData] = useState([])

  const getProfile = async search => {
    if (!search) {
      return setData([])
    }

    try {
      setLoading(true)
      const { data } = await apiService.get(
        `/api/network/connection/mention_profiles${
          search ? `?search_q=${search}` : ""
        }`
      )

      setData(data?.data)
    } catch (e) {
      setData([])
      notifyToast(e)
    }

    setLoading(false)
  }

  const getHashtags = async search => {
    if (!search) {
      return setData([])
    }

    try {
      setLoading(true)
      const { data } = await apiService.get(
        `/api/list/get-hashtags${search ? `?name=${search}` : ""}`
      )

      setData(data?.data)
    } catch (e) {
      setData([])
      notifyToast(e)
    }

    setLoading(false)
  }

  const fetch = (search, prefix) => {
    if (timer) {
      clearTimeout(timer)
    }

    const newTimer = setTimeout(() => {
      if (prefix === "#") {
        getHashtags(search)
      } else {
        getProfile(search)
      }
    }, 300)

    setTimer(newTimer)

    return data
  }

  const options = data?.map(item => ({
    value: item?.name || item?.username,
    label: item?.name ? (
      item.name
    ) : (
      <div className="flex items-center">
        <Avatar
          size={40}
          className="bg-white flex-shrink-0 mr-3"
          src={item?.profile_icon || "/assets/onBoarding/profile_avatar.jpg"}
        />

        <div>
          <div>{item?.full_name || item?.company_name}</div>
          <div className="text-gray-500 f-11">@{item?.username}</div>
        </div>
      </div>
    ),
  }))

  return (
    <Mentions
      rows={rows}
      value={value}
      filterOption={false}
      className={className}
      prefix={["@", "#"]}
      open={data && data.length !== 0}
      loading={loading}
      disabled={disabled}
      autoSize={autoSize}
      placeholder={placeholder}
      notFoundContent=" "
      onSearch={fetch}
      onSelect={() => {
        setData([])
      }}
      onChange={e => {
        onChange(e)
      }}
      options={options}
    />
  )
}
