import { Avatar, Dropdown, Typography } from "antd"
import { AppContext } from "components/AppContext"
import FastAccessMenu from "components/Feed/Posts/FastAccessMenu"
import moment from "moment"
import Link from "next/link"
import { useRouter } from "next/router"
import { useContext } from "react"
import { useSelector } from "react-redux"
import { profileState } from "redux/slices/profileReducer"
import { truncate } from "utils/global"

const { Text } = Typography

export default function PostCardInfo({ previewMode, child }) {
  const { query } = useRouter()
  const { userInfo } = useSelector(profileState)
  const { reference, item } = useContext(AppContext) || {}
  const isAdmin = userInfo?.companies?.find(
    item => item?.username === query?.username
  )

  return (
    <div className="flex">
      <Dropdown
        menu={{
          items: [
            {
              key: item?.id,
              style: { padding: 0 },
              label: <FastAccessMenu item={item} />,
            },
          ],
        }}
        arrow
        placement="bottomRight"
        className="p-0"
      >
        <Avatar
          size={60}
          className="bg-white border border-gray-300 flex-shrink-0"
          src={item?.profile_photo || "/assets/onBoarding/profile_avatar.jpg"}
          alt={item?.full_name}
        />
      </Dropdown>

      <div className="flex flex-col ml-4">
        <div className="flex flex-wrap items-center">
          <Text className="f-17 font-medium">
            {truncate(item?.full_name, {
              length: previewMode ? 40 : 30,
              word: !previewMode,
            })}
          </Text>

          {!previewMode && (
            <>
              {isAdmin && item?.creator_username && (
                <>
                  <span className="text-gray-500 mx-2">. via</span>
                  <Link
                    prefetch={false}
                    passHref
                    href={`/profile/${item?.creator_username}`}
                    key="profile"
                  >
                    <span className="text-primary">
                      {truncate(item?.created_by, { length: 20, word: false })}
                    </span>
                  </Link>
                </>
              )}

              {(reference?.community?.name || item?.community_name) && (
                <>
                  <span className="text-gray-500 mx-2">. via</span>
                  {child ? (
                    <Link
                      passHref
                      prefetch={false}
                      href={`/community/${
                        reference?.community_id ||
                        item?.community_id ||
                        item?.id
                      }`}
                      key="communities"
                      className="text-primary f-14 mr-3"
                    >
                      {truncate(
                        reference?.community?.name || item?.community_name,
                        {
                          length: 20,
                          word: false,
                        }
                      )}
                    </Link>
                  ) : (
                    <Link
                      passHref
                      prefetch={false}
                      href={`/community/${
                        reference?.community_id ||
                        item?.community_id ||
                        item?.id
                      }`}
                      key="communities"
                      className="text-primary f-14 mr-3"
                    >
                      {truncate(
                        reference?.community?.name || item?.community_name,
                        {
                          length: 20,
                          word: false,
                        }
                      )}
                    </Link>
                  )}

                  {(reference?.space_name || item?.space_name) && (
                    <Link
                      passHref
                      prefetch={false}
                      href={`/community/${
                        reference?.community_id ||
                        item?.community_id ||
                        item?.id
                      }/spaces/${item.space_id}`}
                      key="community_space"
                      className="text-primary f-14 mr-3"
                    >
                      {truncate(reference?.space_name || item?.space_name, {
                        length: 20,
                        word: false,
                      })}
                    </Link>
                  )}
                </>
              )}
            </>
          )}
        </div>

        <Text type="secondary" className="f-13">
          {truncate(item?.headline, { length: 50 })}
        </Text>
        <Text type="secondary" className="f-12">
          {moment(item?.created_at).fromNow()}
        </Text>
      </div>
    </div>
  )
}
