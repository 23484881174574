import { Button, Card, Divider, Skeleton } from "antd"
import { AppContext } from "components/AppContext"
import AddComment from "components/Feed/Comments/AddComment"
import CommentCard from "components/Feed/Comments/CommentCard"
import { useContext, useEffect, useState } from "react"
import { useSelector } from "react-redux"
import { communityReduxState } from "redux/slices/communitySlice"
import { companyReduxState } from "redux/slices/companySlice"
import { profileState } from "redux/slices/profileReducer"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

const CommentList = () => {
  const [page, setPage] = useState(1)
  const [items, setItems] = useState([])
  const [newComments, setNewComment] = useState([])
  const {
    instance,
    setInstance,
    communityDetails = null,
    companyDetails = null,
  } = useContext(AppContext) || {}

  const [showMore, setMore] = useState(false)
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState(null)

  const { userInfo } = useSelector(profileState)
  const { userPolicies: companyPolicies } = useSelector(companyReduxState)
  const { userPolicies: communityPolicies } = useSelector(communityReduxState)
  const isLastPage = pagination?.current_page >= pagination?.last_page

  let addCommentPermission = true
  const isMe =
    (instance?.creator_profile_id || instance?.profile_id) ===
    userInfo?.profile_id

  if (!isMe) {
    if (communityDetails) {
      addCommentPermission =
        !["general"].includes(instance?.privacy) &&
        communityPolicies?.some(policy => ["create_comment"].includes(policy))
    } else if (companyDetails) {
      addCommentPermission = companyPolicies?.some(policy =>
        ["create_comment"].includes(policy)
      )
    }
  }

  const fetchData = async () => {
    try {
      setLoading(true)
      const res = await apiService.get(
        `/api/network/post/${instance?.id}/comment/?page=${page}`
      )

      if (page > 1) {
        setItems(prev => [...prev, ...res.data.data])
      } else {
        setItems(res?.data?.data)
      }
      setNewComment([])
      setPagination(res?.data?.meta)
    } catch (e) {
      notifyToast(e)
    }

    setLoading(false)
  }

  const onCommentAdded = newItem => {
    setNewComment([newItem, ...newComments])
    setInstance({ ...instance, comments: (instance?.comments || 0) + 1 })
  }

  const loadMoreData = () => {
    if (!showMore) {
      return setMore(true)
    }
    if (!isLastPage) {
      setPage(page + 1)
    }
  }

  useEffect(() => {
    fetchData()
  }, [page])

  return (
    <>
      {addCommentPermission && (
        <>
          <Divider className="my-4 bg-gray-100" style={{ height: 1 }} />
          <AddComment
            large
            postId={instance?.id}
            companyId={companyDetails?.profile_id}
            onCommentAdded={onCommentAdded}
          />
        </>
      )}

      <Divider className="my-4 bg-gray-100" style={{ height: 1 }} />

      {newComments?.map((comment, index) => (
        <CommentCard
          large
          mine
          orange={index === 0}
          key={index}
          item={comment}
          postId={instance?.id}
          companyId={companyDetails?.profile_id}
          onRemove={() => {
            const prev = [...newComments]
            prev.splice(index, 1)

            setNewComment(prev)
            setInstance({
              ...instance,
              comments: (instance?.comments || 1) - 1,
            })
          }}
        />
      ))}

      {items?.length !== 0 ? (
        <div>
          {items?.map(
            (comment, index) =>
              index <= (showMore ? items?.length - 1 : 2) && (
                <CommentCard
                  large
                  key={index}
                  item={comment}
                  postId={instance?.id}
                  companyId={companyDetails?.profile_id}
                  fetchData={fetchData}
                  onRemove={() =>
                    setInstance({
                      ...instance,
                      comments: (instance?.comments || 1) - 1,
                    })
                  }
                />
              )
          )}

          {!loading && items?.length > 2 && (showMore ? !isLastPage : true) && (
            <div className="flex">
              <Button type="link" className="p-0" onClick={loadMoreData}>
                Load More
              </Button>
            </div>
          )}
        </div>
      ) : (
        !loading &&
        newComments?.length === 0 && (
          <div className="pb-2 text-gray-500 f-13">
            No comment on this post!
          </div>
        )
      )}

      {loading && (
        <Card
          className="border-none"
          bodyStyle={{ padding: 12 }}
          style={{ borderRadius: 15, backgroundColor: "#f9f9f9" }}
        >
          <Skeleton className="small-skeleton" active paragraph={{ rows: 1 }} />
        </Card>
      )}
    </>
  )
}

export default CommentList
