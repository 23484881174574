import { CloseOutlined } from "@ant-design/icons"
import { Tag } from "antd"
import CPCascader from "components/CP/CPCascader"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getServiceCategoriesList,
  serviceCategoriesReduxState,
} from "redux/slices/serviceCategoriesSlice"

function ServiceCategoriesDropdownField({
  handleRemove,
  addNewItem = true,
  ...props
}) {
  const dispatch = useDispatch()
  const dataInRedux = useSelector(serviceCategoriesReduxState)

  useEffect(() => {
    if (!dataInRedux?.data) {
      dispatch(getServiceCategoriesList())
    }
  }, [])

  const renderSelectedValues = () => {
    return props?.selectedValues?.map((item, index) => {
      const found = dataInRedux?.data
        ?.flatMap(cg => [cg, ...cg.children])
        ?.find(c => c.id === item)

      return (
        <Tag
          key="Tag"
          closable={handleRemove}
          closeIcon={<CloseOutlined />}
          onClose={e => {
            e.preventDefault()
            handleRemove(item, index)
          }}
          className="text-xs rounded-lg py-1 border-gray-300 mb-1 mr-1 inline-flex items-center"
        >
          {found?.title || item}
        </Tag>
      )
    })
  }

  return (
    <>
      <CPCascader
        {...props}
        search={addNewItem}
        className="w-full"
        disabled={dataInRedux?.loading}
        loading={dataInRedux?.loading}
        data={dataInRedux?.data?.map(item => ({
          label: item?.label,
          value: item?.label,
          children: item?.children?.map(subItem => ({
            label: subItem?.title,
            value: subItem?.id,
          })),
        }))}
      >
        {props?.children || null}
      </CPCascader>

      {props?.selectedValues && (
        <div className="mt-2">{renderSelectedValues()}</div>
      )}
    </>
  )
}

export default ServiceCategoriesDropdownField
