import CPSelect from "components/CP/CPSelect"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getProjectTypesList,
  projectTypesReduxState,
} from "redux/slices/projectTypesSlice"

function ProjectTypeDropDownField(props) {
  const dataInRedux = useSelector(projectTypesReduxState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!dataInRedux?.data) {
      dispatch(getProjectTypesList())
    }
  }, [])

  return (
    <CPSelect
      {...props}
      loading={dataInRedux?.loading}
      disabled={dataInRedux?.loading}
      options={[
        ...(dataInRedux?.data?.map(item => {
          return { label: item?.title, value: item?.id }
        }) || []),
        { label: "--- Other ---", value: 0 },
      ]}
    />
  )
}

export default ProjectTypeDropDownField
