import CPSelect from "components/CP/CPSelect"
import { useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  getProjectFunctionsList,
  projectFunctionsReduxState,
} from "redux/slices/projectFunctionsSlice"

function ProjectFunctionsDropDownField(props) {
  const dataInRedux = useSelector(projectFunctionsReduxState)
  const dispatch = useDispatch()

  useEffect(() => {
    if (!dataInRedux?.data) {
      dispatch(getProjectFunctionsList())
    }
  }, [])

  return (
    <CPSelect
      {...props}
      loading={dataInRedux?.loading}
      disabled={dataInRedux?.loading}
      options={dataInRedux?.data?.map(item => {
        return { label: item?.title, value: item?.id }
      })}
    />
  )
}

export default ProjectFunctionsDropDownField
