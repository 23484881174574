import { Button, Col, Form, Image, Row, Tooltip } from "antd"
import { AppContext } from "components/AppContext"
import { CPModal } from "components/CP/CPModal"
import DocumentGallery from "components/CustomFields/DocumentGallery"
import ImageGallery from "components/CustomFields/ImageGallery"
import VideoGallery from "components/CustomFields/VideoGallery"
import AddAssetModal from "components/Feed/AddPost/AddAssetModal"
import AddPostCategory from "components/Feed/AddPost/AddPostCategory"
import AddPostContentForm from "components/Feed/AddPost/AddPostContentForm"
import AddPostSpace from "components/Feed/AddPost/AddPostSpace"
import AddPostVisibility from "components/Feed/AddPost/AddPostVisibility"
import AddProductModalContent from "components/Profile/editModals/AddProductModalContent/AddProductModalContent"
import AddProjectModal from "components/Profile/editModals/AddProjectModal"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useContext, useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import {
  addPostState,
  setReference,
  toggleDialog,
} from "redux/slices/addPostSlice"
import { feedState } from "redux/slices/feedSlice"
import apiService from "services/apiService"
import { notifyUser } from "services/notifyToast"
import { truncate } from "utils/global"

const categories = [
  {
    title: "Quick Post",
    value: "quick_post",
    description: "Anyone can have access to your post",
  },
  {
    title: "Portfolio+",
    value: "portfolio",
    showMore: "icon-ic_keyboard_arrow_right_24px f-13 ml-3",
    description: "Add project to your portfolio and share it with others",
  },
]

const visibilities = [
  {
    icon: "icon-ic_public_24px",
    name: "Public",
    value: "public",
    description: "Anyone can have access to your post",
  },
  {
    icon: "icon-ic_people_24px",
    name: "Connections",
    value: "connections",
    description: "Your connections on Birdflocks",
  },
  {
    icon: "icon-ic_community_24px",
    name: "Community Members",
    value: "community",
    showMore: "icon-ic_keyboard_arrow_right_24px f-13 ml-3",
    description: "Members within following communities in Birdflocks",
  },
]

const portfolioTypes = [
  { title: "Add Project", value: "project" },
  { title: "Add Product", value: "product" },
]

const baseUrl = process.env.NEXT_PUBLIC_BASE_URL

export default function AddPostModal({
  url,
  onFetch,
  onUpdate,
  showModal = false,
  toggleModal,
  disablePrivacy = false,
} = {}) {
  const { checkSubscription } = useSubscriptionContext()
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const { editMode } = useSelector(feedState)
  const { showPanel, referencePost } = useSelector(addPostState || false)

  const {
    companyDetails = null,
    communityDetails = null,
    communitySpaces = null,
    selectedSpace = null,
  } = useContext(AppContext) || {}

  const companyId =
    companyDetails?.profile_id || referencePost?.companyId || null
  const communityId =
    communityType?.id || editMode?.community_id || communityDetails?.id

  const [title, setTitle] = useState("")
  const [description, setDescription] = useState("")
  const [portfolioType, setPortfolioType] = useState(null)
  const [communityType, setCommunityType] = useState(null)
  const [savedPhoto, setSavedPhoto] = useState([])
  const [savedVideo, setSavedVideo] = useState([])
  const [savedDocument, setSavedDocument] = useState([])
  const [category, setCategory] = useState(categories[0])
  const [visibility, setVisibility] = useState(visibilities[0])
  const [loading, setLoading] = useState(false)
  const [showPhoto, togglePhoto] = useState(false)
  const [showVideo, toggleVideo] = useState(false)
  const [showSpaces, toggleSpaces] = useState(false)
  const [showDocument, toggleDocument] = useState(false)
  const [showCategory, toggleCategory] = useState(false)
  const [showVisibility, toggleVisibility] = useState(false)
  const [showAddProject, toggleAddProject] = useState(false)
  const [showAddProduct, toggleAddProduct] = useState(false)
  const [projectInitialData, setProjectInitialData] = useState(
    editMode?.project
  )

  useEffect(() => {
    if (editMode?.title) {
      setTitle(editMode?.title)
    }
    if (editMode?.body) {
      setDescription(
        editMode?.body.replace(/<a\b[^>]*>/gi, "").replace(/<\/a>/gi, "")
      )
    }

    if (editMode?.post_category === "project") {
      toggleAddProject(true)
    } else if (editMode?.post_category === "product") {
      toggleAddProduct(true)
    } else if (editMode?.post_category) {
      setCategory(
        categories?.find(item => item.value === editMode?.post_category) ||
          categories[0]
      )
    } else {
      setCategory(categories[0])
    }

    if (!editMode?.community_id) {
      const find =
        visibilities.find(item => item.value === editMode?.post_visibility) ||
        visibilities[0]
      setVisibility(find)
    }

    if (editMode?.files?.length) {
      if (editMode?.files?.some(item => /(.pdf|.mov|.mp4)$/.test(item.url))) {
        const result = editMode?.files.map(item => ({
          ...item,
          fullUrl: `${baseUrl}/api/files/?url=${item.url}`,
        }))

        if (editMode?.files?.some(item => /(.pdf)$/.test(item.url))) {
          return setSavedDocument(result)
        }
        return setSavedVideo(result)
      }

      setSavedPhoto(editMode?.files)
    }
  }, [editMode])

  useEffect(() => {
    return () => {
      dispatch(setReference(null))
    }
  }, [dispatch, showPanel])

  useEffect(() => {
    const adapted = adaptProjectInitialData(editMode?.project)
    setProjectInitialData(adapted)
  }, [])

  const adaptProjectInitialData = data => {
    const adapted = Object.assign({}, data)
    adapted.project_service_category_ids =
      adapted.project_service_categories?.map(item => item.id)
    adapted.project_function_ids = adapted.project_functions?.map(
      item => item.id
    )

    return adapted
  }
  const onSubmit = async () => {
    const files = savedPhoto?.length
      ? savedPhoto
      : savedVideo?.length
        ? savedVideo
        : savedDocument?.length
          ? savedDocument
          : []
    const post_files = files?.map(item => ({
      url: item?.response || item.url,
    }))

    if (!(title || description) && files.length === 0) {
      return notifyUser({
        type: "error",
        description: "Upload or post something on your feed.",
      })
    }

    try {
      setLoading(true)
      const body = {
        post_files: post_files?.length ? post_files : null,
        hashtags: [],
        body: description,
        title: title,
        reference_post_id: referencePost
          ? referencePost?.reference_post_id || referencePost?.id
          : null,
        post_category: category?.value || "quick_post",
        post_visibility:
          visibility?.value === "community"
            ? "public"
            : visibility?.value || "public",
        comment_visibility: "public",
      }

      if (editMode) {
        await apiService.put(
          url ||
            `/api/network${companyId ? `/company/${companyId}` : ""}/post/${
              editMode?.id
            }`,
          body
        )
        if (onUpdate) {
          onUpdate()
        }
      } else if (referencePost) {
        await apiService.post(
          url ||
            `/api/${communityId ? `community/${communityId}` : "network"}/post`,
          body
        )
        if (onFetch) {
          onFetch()
        }
      } else {
        await apiService.post(
          url ||
            `/api/${
              communityId
                ? `community/${communityId}`
                : `network${companyId ? `/company/${companyId}` : ""}`
            }/post`,
          body
        )
        if (onFetch) {
          onFetch()
        }
      }

      notifyUser({
        type: "success",
        description: "Your post submitted successfully",
      })

      dispatch(toggleDialog(false))
      if (toggleModal) {
        toggleModal(false)
      }
    } catch (e) {
      checkSubscription(e)
      setLoading(false)
    }
  }

  const modalTitle = () => {
    if (editMode) {
      return "Edit Post"
    }
    if (showPhoto) {
      return "Add Photos"
    }
    if (showVideo) {
      return "Add Videos"
    }
    if (showCategory) {
      return "Choose Post Type"
    }
    if (showSpaces) {
      return "Choose a Space"
    }
    if (showDocument) {
      return "Add Documents"
    }
    if (showVisibility) {
      return "Who can see your post?"
    }
    if (referencePost) {
      return "Share a Post"
    }
    if (selectedSpace) {
      return "Create a Quick Post"
    }

    return "Create a Post"
  }

  return (
    <CPModal
      width={showAddProduct ? "50%" : undefined}
      centered
      footer={false}
      destroyOnClose
      open={showPanel || showModal}
      maskClosable={false}
      title={modalTitle()}
      className="rounded-lg p-0"
      onCancel={() => {
        dispatch(toggleDialog(false))
        if (toggleModal) {
          toggleModal(false)
        }
      }}
    >
      {!showCategory &&
        !showSpaces &&
        !showVisibility &&
        !showPhoto &&
        !showVideo &&
        !showDocument &&
        !showAddProduct && (
          <AddPostContentForm
            form={form}
            loading={loading}
            editMode={editMode}
            companyId={companyId}
            setTitle={setTitle}
            title={title}
            description={description}
            setDescription={setDescription}
            onSubmit={onSubmit}
            noAttachment={
              savedPhoto?.length === 0 &&
              savedVideo?.length === 0 &&
              savedDocument?.length === 0
            }
            files={
              <>
                {savedPhoto?.length !== 0 && (
                  <Image.PreviewGroup>
                    <Row
                      gutter={[8, 4]}
                      className="rounded-lg overflow-hidden mt-4"
                    >
                      {savedPhoto?.map((item, index) => (
                        <ImageGallery
                          key={index}
                          index={index}
                          getFile={!!item.url}
                          onGetImage={({ file, index }) => {
                            if (!file) {
                              return
                            }
                            const instance = savedPhoto ? [...savedPhoto] : []
                            const selected = instance[index]
                            instance[index] = { file, url: selected.url }
                            setSavedPhoto(instance)
                          }}
                          src={item.file || item.url}
                          fileLength={savedPhoto?.length}
                          onRemove={() =>
                            setSavedPhoto(
                              savedPhoto.filter((_, i) => i !== index)
                            )
                          }
                        />
                      ))}
                    </Row>
                  </Image.PreviewGroup>
                )}
                {savedVideo?.length !== 0 && (
                  <Row
                    gutter={[8, 4]}
                    className="rounded-lg overflow-hidden mt-4"
                  >
                    {savedVideo?.map((item, index) => (
                      <Col xs={24} key={index}>
                        <VideoGallery
                          key={index}
                          onRemove={() => setSavedVideo([])}
                          item={item}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
                {savedDocument?.length !== 0 && (
                  <Row
                    gutter={[8, 4]}
                    className="rounded-lg overflow-hidden mt-4"
                  >
                    {savedDocument?.map((item, index) => (
                      <Col xs={24} key={index}>
                        <DocumentGallery
                          key={index}
                          onRemove={() => setSavedDocument([])}
                          item={item}
                        />
                      </Col>
                    ))}
                  </Row>
                )}
              </>
            }
            settings={
              <>
                {communitySpaces?.length ? (
                  <Button
                    type="light"
                    className="my-1 mr-3 ml-0 bg-white "
                    style={{ color: "#5a5a5a" }}
                    onClick={() => toggleSpaces(true)}
                  >
                    <div className="flex items-center">
                      {selectedSpace ? (
                        <span
                          className="inline-block truncate"
                          style={{ maxWidth: 120 }}
                        >
                          {selectedSpace?.name}
                        </span>
                      ) : (
                        "Spaces"
                      )}
                      <span className="icon-ic_arrow_drop_down_24px f-5 text-primary ml-2 inline-block" />
                    </div>
                  </Button>
                ) : (
                  <Button
                    type="light"
                    className="my-1 mr-3 ml-0 bg-white"
                    style={{ color: "#5a5a5a" }}
                    onClick={() => toggleCategory(true)}
                  >
                    <div className="flex items-center">
                      {category ? (
                        <span className="inline-block">{category?.title}</span>
                      ) : (
                        "Post Category"
                      )}
                      <span className="icon-ic_arrow_drop_down_24px f-5 text-primary ml-2 inline-block" />
                    </div>
                  </Button>
                )}
                {!disablePrivacy &&
                  (["public"].includes(editMode?.privacy)
                    ? true
                    : !editMode?.community_id) && (
                    <Button
                      type="light"
                      className="my-1 mr-0 bg-white"
                      style={{ color: "#5a5a5a" }}
                      onClick={() => toggleVisibility(true)}
                    >
                      <div className="flex items-center">
                        {visibility ? (
                          communityType ? (
                            <>
                              <span
                                className={`${visibility?.icon} inline-block mr-2`}
                              />
                              <span className="inline-block">
                                {truncate(communityType?.name + " Community", {
                                  length: 15,
                                  word: false,
                                })}
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                className={`${visibility?.icon} inline-block mr-2`}
                              />
                              <span className="ml-2 inline-block">
                                {visibility?.name}
                              </span>
                            </>
                          )
                        ) : (
                          "Post Visibility"
                        )}

                        <span className="icon-ic_arrow_drop_down_24px f-5 text-primary ml-2 inline-block" />
                      </div>
                    </Button>
                  )}
              </>
            }
          >
            <Tooltip placement="bottom" title="Add images">
              <Button
                type="link"
                disabled={
                  savedVideo?.length ||
                  savedDocument?.length ||
                  (referencePost && !editMode)
                }
                className="px-1 py-0 bg-transparent"
                style={{
                  height: "26px",
                  minHeight: "26px",
                  color: `${
                    savedVideo?.length ||
                    savedDocument?.length ||
                    (referencePost && !editMode)
                      ? "#bfbfbf"
                      : "#5a5a5a"
                  }`,
                }}
                onClick={() => togglePhoto(!showPhoto)}
              >
                <span className="icon-ic_photo_library_24px f-23" />
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title="Add videos">
              <Button
                type="link"
                disabled={
                  savedPhoto?.length ||
                  savedDocument?.length ||
                  (referencePost && !editMode)
                }
                className="px-1 py-0 bg-transparent"
                style={{
                  height: "26px",
                  minHeight: "26px",
                  color: `${
                    savedPhoto?.length ||
                    savedDocument?.length ||
                    (referencePost && !editMode)
                      ? "#bfbfbf"
                      : "#5a5a5a"
                  }`,
                }}
                onClick={() => toggleVideo(!showVideo)}
              >
                <span className="icon-ic_video_library_24px f-23" />
              </Button>
            </Tooltip>
            <Tooltip placement="bottom" title="Add document">
              <Button
                type="link"
                disabled={
                  savedPhoto?.length ||
                  savedVideo?.length ||
                  (referencePost && !editMode)
                }
                className="px-1 py-0 bg-transparent"
                style={{
                  height: "26px",
                  minHeight: "26px",
                  color: `${
                    savedPhoto?.length ||
                    savedVideo?.length ||
                    (referencePost && !editMode)
                      ? "#bfbfbf"
                      : "#5a5a5a"
                  }`,
                }}
                onClick={() => toggleDocument(!showDocument)}
              >
                <span className="icon-ic_library_books_24px f-23" />
              </Button>
            </Tooltip>
          </AddPostContentForm>
        )}
      {showCategory && (
        <AddPostCategory
          category={category}
          categories={
            savedPhoto?.length !== 0 ||
            savedVideo?.length !== 0 ||
            savedDocument?.length !== 0
              ? categories.filter(item => item.value !== "portfolio")
              : categories
          }
          setCategory={setCategory}
          portfolioTypes={portfolioTypes}
          portfolioType={portfolioType}
          setPortfolioType={event => {
            toggleCategory(false)

            if (event?.value === "project") {
              toggleAddProject(true)
            } else if (event?.value === "product") {
              toggleAddProduct(true)
            } else {
              setPortfolioType(event)
            }
          }}
          onBack={() => toggleCategory(false)}
        />
      )}
      {showSpaces && <AddPostSpace onBack={() => toggleSpaces(false)} />}
      {!disablePrivacy && showVisibility && (
        <AddPostVisibility
          visibility={visibility}
          visibilities={
            communityDetails
              ? visibilities.slice(0, -1)
              : companyDetails
                ? visibilities?.filter(item => item.value !== "community")
                : visibilities
          }
          setVisibility={setVisibility}
          communityType={communityType}
          setCommunityType={setCommunityType}
          onBack={() => toggleVisibility(false)}
        />
      )}
      {(showPhoto || showVideo || showDocument) && (
        <AddAssetModal
          optionData={() => {
            if (showPhoto) {
              return {
                max: 10,
                type: "photo",
                replace: "+ Add more photos",
                accept: "image/png,image/jpg,image/jpeg,image/gif",
                placeholder: "Drag maximum 10 photos here or",
                hint: "Upload .jpg, .gif, or .png images up to 5MB each.",
              }
            }
            if (showVideo) {
              return {
                max: 1,
                type: "video",
                accept: "video/*",
                replace: "Change Video",
                placeholder: "Drag a video here or",
                hint: "Upload .mp4 and .mov Video up to 500MB.",
              }
            }
            if (showDocument) {
              return {
                max: 1,
                type: "document",
                accept: ".pdf",
                replace: "Change Document",
                placeholder: "Drag a document here or",
                hint: "Upload .pdf Document up to 25MB.",
              }
            }
          }}
          data={() => {
            if (showPhoto) {
              return savedPhoto
            }
            if (showVideo) {
              return savedVideo
            }
            if (showDocument) {
              return savedDocument
            }
          }}
          setData={input => {
            if (showPhoto) {
              setSavedPhoto(input)
            }
            if (showVideo) {
              setSavedVideo(input)
            }
            if (showDocument) {
              setSavedDocument(input)
            }
          }}
          onBack={() => {
            togglePhoto(false)
            toggleVideo(false)
            toggleDocument(false)
          }}
        />
      )}
      {showAddProject && (
        <AddProjectModal
          showModal={showAddProject}
          initialData={projectInitialData}
          companyProfileId={companyDetails?.company_profile_id}
          communityId={communityId}
          refreshData={() => {
            if (onFetch) {
              onFetch()
            }
          }}
          hideModal={() => {
            toggleAddProject(false)
            dispatch(toggleDialog(false))
            if (toggleModal) {
              toggleModal(false)
            }
          }}
        />
      )}
      {showAddProduct && (
        <AddProductModalContent
          showModal={showAddProduct}
          initialData={editMode?.product}
          companyProfileId={companyDetails?.company_profile_id}
          communityId={communityId}
          refreshData={() => {
            if (onFetch) {
              onFetch()
            }
          }}
          hideModal={() => {
            toggleAddProduct(false)
            dispatch(toggleDialog(false))
            if (toggleModal) {
              toggleModal(false)
            }
          }}
        />
      )}
    </CPModal>
  )
}
