import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Button, Carousel, Col, Row, Upload } from "antd"
import DocumentGallery from "components/CustomFields/DocumentGallery"
import ImageGallery from "components/CustomFields/ImageGallery"
import VideoGallery from "components/CustomFields/VideoGallery"
import { useEffect, useMemo, useRef, useState } from "react"
import { notifyUser } from "services/notifyToast"
import { guid } from "utils/global"
import { getItem } from "utils/storage"

export default function AddAssetModal({
  onBack,
  data,
  setData,
  optionData,
  defaultIndex = 0,
}) {
  const baseUrl = process.env.NEXT_PUBLIC_BASE_URL
  const accessToken = getItem("access_token")
  const carousel = useRef(null)
  const { Dragger } = Upload
  const options = optionData()
  const [files, setFiles] = useState([])
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    if (!data) {
      return
    }
    const assetFiles = data()
    if (assetFiles) {
      setFiles(assetFiles.map(item => ({ ...item, uid: guid() })))
    }
  }, [data])

  useEffect(() => {
    if (!carousel?.current) {
      return
    }
    carousel?.current?.goTo(defaultIndex, true)
  }, [carousel?.current, defaultIndex])

  const onRemove = file => {
    const newItem = files.filter(item => item.uid !== file.uid)
    setFiles(newItem)
  }

  const onChange = ({ file }) => {
    if (!file) {
      return
    }
    const { status } = file
    if (status === "done") {
      const mFile = {
        uid: guid(),
        title: file.name,
        file: URL.createObjectURL(file.originFileObj),
        response: file.response.data,
      }
      const originalFiles = [mFile, ...files].splice(0, options?.max)
      setFiles(originalFiles)
      setLoading(false)
    } else if (status === "error") {
      notifyUser({ title: `${file.name} file upload failed.` })
      setLoading(false)
    }
  }

  const container = useMemo(() => {
    switch (options?.type) {
      case "photo":
        return files?.map((item, index) => (
          <Row gutter={[0, 0]} className="relative" key={index} align="center">
            <ImageGallery
              src={item.file || item.url}
              getFile={!!item.url}
              fileLength={1}
              onRemove={() => onRemove(item)}
            />
          </Row>
        ))
      case "document":
        return files?.map((item, index) => (
          <div className="relative" key={index}>
            <DocumentGallery item={item} onRemove={() => onRemove(item)} />
          </div>
        ))
      case "video":
        return files?.map((item, index) => {
          return (
            <div className="relative" key={index}>
              <VideoGallery item={item} onRemove={() => onRemove(item)} />
            </div>
          )
        })
    }
  }, [files, options])

  const draggerMarkup = (
    <Col>
      <div>
        <Dragger
          className="mb-4"
          multiple
          maxCount={options?.max}
          name="file"
          action={`${baseUrl}/api/files/`}
          accept={options?.accept}
          beforeUpload={() => {
            setLoading(true)
            return true
          }}
          showUploadList={false}
          headers={{
            authorization: `Bearer ${accessToken}`,
          }}
          progress={{
            strokeWidth: 3,
            format: percent => `${parseInt(percent.toFixed(2))}%`,
          }}
          onChange={onChange}
        >
          <div className="f-15 text-center text-gray-500 m-0 font-bold">
            {options?.placeholder}
            <span className="text-primary px-1">browse</span>
            to upload
          </div>
        </Dragger>
      </div>
      <div className="text-center text-gray-500 f-14 mt-4">{options?.hint}</div>
    </Col>
  )

  return (
    <div className="p-5">
      <div>
        {files?.length !== 0 ? (
          <Carousel
            dots
            dotPosition="bottom"
            arrows={files?.length > 1}
            prevArrow={<LeftOutlined />}
            nextArrow={<RightOutlined />}
            className="overflow-hidden relative"
            ref={carousel}
          >
            {container}
          </Carousel>
        ) : (
          draggerMarkup
        )}
      </div>

      <Col>
        <div className="pt-6">
          {files?.length !== 0 &&
            (options?.max > 1 ? files?.length < options?.max : true) &&
            draggerMarkup}
        </div>

        <div className="pt-6 flex items-center">
          <div className="flex-grow pl-3" />

          <Button
            type="default"
            className="px-8"
            disabled={loading}
            onClick={onBack}
          >
            Back
          </Button>
          <Button
            type="primary"
            className="px-8"
            htmlType="submit"
            disabled={loading}
            loading={loading}
            onClick={() => {
              setData(files)
              onBack()
            }}
          >
            Save
          </Button>
        </div>
      </Col>
    </div>
  )
}
