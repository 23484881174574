import { Avatar, Badge, Button } from "antd"
import { AppContext } from "components/AppContext"
import AddMention from "components/Editor/Mention"
import style from "components/Feed/Comments/comment.module.scss"
import { useSubscriptionContext } from "components/Subscription/SubscriptionProvider"
import { useContext, useState } from "react"
import { useSelector } from "react-redux"
import { profileState } from "redux/slices/profileReducer"
import apiService from "services/apiService"

const AddComment = ({
  className = "",
  large,
  postId,
  parentId,
  onCommentAdded,
}) => {
  const { checkSubscription } = useSubscriptionContext()
  const { userInfo } = useSelector(profileState)
  const [loading, setLoading] = useState(false)
  const [comment, setComment] = useState("")
  const { companyDetails = null } = useContext(AppContext) || {}

  const handleSubmit = async event => {
    event.preventDefault()

    if (!comment) {
      return
    }

    try {
      setLoading(true)

      const body = { body: comment }
      if (parentId) {
        body.reference_comment_id = parentId
      }

      const res = await apiService.post(
        `/api/network${
          companyDetails ? `/company/${companyDetails?.profile_id}` : ""
        }/post/${postId}/comment`,
        body
      )

      setComment("")
      onCommentAdded(res?.data?.data)
    } catch (e) {
      checkSubscription(e)
    }

    setLoading(false)
  }

  return (
    <form
      className={`flex justify-center ${className}`}
      onSubmit={handleSubmit}
    >
      <Badge
        dot
        style={{ backgroundColor: "#00a23d" }}
        offset={[-7, 42]}
        size={20}
      >
        <Avatar
          size={large ? 52 : 49}
          className="bg-white border border-gray-300"
          src={
            companyDetails?.img ||
            userInfo?.icon ||
            "/assets/onBoarding/profile_avatar.jpg"
          }
        />
      </Badge>

      <div className="flex w-full relative">
        <AddMention
          rows={2}
          value={comment}
          disabled={loading}
          autoSize={{ minRows: 2, maxRows: 6 }}
          className={`${style.mentionInput} ml-3`}
          placeholder={`Add a ${large ? "comment" : "reply"}`}
          onChange={setComment}
        />

        <Button
          type="link"
          loading={loading}
          disabled={loading}
          className={`${style.submitButton} text-primary-dark px-4`}
          htmlType="submit"
        >
          Post
        </Button>
      </div>
    </form>
  )
}

export default AddComment
