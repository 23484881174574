import { Spin } from "antd"
import CPSelect from "components/CP/CPSelect"
import { useState } from "react"
import apiService from "services/apiService"
import { notifyToast } from "services/notifyToast"

export default function IndividualSelectWithSearch(props) {
  const [searchedUsers, setSearchedUsers] = useState([])
  const [fetchingUsers, setFetchingUsers] = useState(false)

  const fetchUser = async value => {
    try {
      setSearchedUsers([])
      setFetchingUsers(true)

      const res = await apiService.get(
        `/api/list/get-individuals?search_q=${value}&with_pagination=0`
      )
      setFetchingUsers(false)

      if (res.status === 200 && res?.data?.data?.length) {
        const data = res?.data?.data?.map(user => ({
          label: `${user.name}`,
          value: user.id,
        }))
        setSearchedUsers(data)
      }
    } catch (e) {
      notifyToast(e)
    }
  }

  return (
    <CPSelect
      {...props}
      mode="tags"
      labelInValue
      optionFilterProp="label"
      filterOption={false}
      notFoundContent={fetchingUsers ? <Spin size="small" /> : null}
      onSearch={fetchUser}
      options={searchedUsers}
    />
  )
}
