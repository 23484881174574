import { LeftOutlined, RightOutlined } from "@ant-design/icons"
import { Carousel, Col, Image, Row } from "antd"
import { useEffect, useState } from "react"

const titleClass = "text-white "
const valClass = "font-light text-white"

export default function PreviewContent({ data, type }) {
  const [activeSlide, setActiveSlide] = useState(1)
  const [showPreview, setShowPreview] = useState(false)
  const PreviewField = ({ title, value, defined = true }) => {
    if (defined) {
      return (
        <>
          <Col xs={8}>
            <div className={titleClass}>{title}</div>
          </Col>
          <Col xs={16}>
            <div className={valClass}>{value === "" ? "_" : value}</div>
          </Col>
        </>
      )
    }
  }

  const projectFields = [
    "title",
    "project_description",
    "project_type_id",
    "project_type_other",
    "client",
    "collaborator_ids",
    "collaborator_names",
    "size",
    "country_id",
    "city_id",
    "latitude",
    "longitude",
    "project_status_id",
    "project_function_ids",
    "project_service_category_ids",
    "team_member_ids",
    "team_member_names",
    "images",
    "captions",
    "cover_index",
    "post_on_feed",
    "post_visibility",
    "community_id",
  ]

  const productFields = {
    4: [
      "title",
      "description",
      "product_category_id",
      "status",
      "availability",
      "service_area_ids",
      "available_from",
      "collaborator_ids",
      "collaborator_names",
      "team_member_ids",
      "team_member_names",
      "buyers",
      "target_audience",
      "read_more_links",
      "images",
      "captions",
      "cover_index",
      "post_on_feed",
      "post_visibility",
      "community_id",
      "space_id",
    ],
    5: [
      "title",
      "description",
      "product_category_id",
      "availability",
      "dimension",
      "manufacture_date",
      "available_from",
      "product_properties_ids",
      "product_material_ids",
      "collaborator_ids",
      "collaborator_names",
      "team_member_ids",
      "team_member_names",
      "buyers",
      "target_audience",
      "read_more_links",
      "images",
      "captions",
      "cover_index",
      "post_on_feed",
      "post_visibility",
      "community_id",
      "space_id",
    ],
    6: [
      "title",
      "description",
      "product_category_id",
      "available_from",
      "measure",
      "collaborator_ids",
      "collaborator_names",
      "team_member_ids",
      "team_member_names",
      "buyers",
      "target_audience",
      "read_more_links",
      "images",
      "captions",
      "cover_index",
      "post_on_feed",
      "post_visibility",
      "community_id",
      "space_id",
    ],
    7: [
      "title",
      "description",
      "product_category_id",
      "availability",
      "service_area_ids",
      "available_from",
      "collaborator_ids",
      "collaborator_names",
      "team_member_ids",
      "team_member_names",
      "buyers",
      "target_audience",
      "read_more_links",
      "images",
      "captions",
      "cover_index",
      "post_on_feed",
      "post_visibility",
      "community_id",
      "space_id",
    ],
  }

  const fields =
    type === "project"
      ? projectFields
      : productFields[data?.product_category?.id]

  const renderProjectDetails = (
    <>
      <Row gutter={[15, 15]}>
        <Col xs={24} sm={14}>
          <Row gutter={[8, 8]}>
            <PreviewField
              defined={
                fields?.includes("project_type_other") ||
                fields?.includes("project_type_title")
              }
              title={"Type"}
              value={data?.project_type_other || data?.project_type_title}
            />
            <PreviewField
              defined={fields?.includes("country") || fields?.includes("city")}
              title={"Location"}
              value={
                data?.country || data?.city ? (
                  <div>
                    {data?.city && `${data?.city}, `}
                    {data?.country}
                  </div>
                ) : null
              }
            />
            <PreviewField
              defined={fields?.includes("project_status_title")}
              title={"Project Status"}
              value={data?.project_status_title}
            />
            <PreviewField
              defined={fields?.includes("project_functions")}
              title={"Function"}
              value={
                data?.project_functions?.length > 0 &&
                data?.project_functions?.map(item => item?.title).join(", ")
              }
            />
            <PreviewField
              defined={fields?.includes("project_service_categories")}
              title={"Service Type"}
              value={
                data?.project_service_categories?.length > 0 &&
                data?.project_service_categories
                  ?.map(item => item?.title)
                  .join(", ")
              }
            />
          </Row>
        </Col>
        <Col xs={24} sm={10}>
          <Row gutter={[8, 8]}>
            <PreviewField
              title={"Size"}
              value={data?.size}
              defined={fields?.includes("size")}
            />
            <PreviewField
              title={"Client"}
              value={data?.client}
              defined={fields?.includes("client")}
            />
            {data?.team_members?.length > 0 && (
              <PreviewField
                defined={fields?.includes("team_member_names")}
                title={"Team"}
                value={
                  data?.team_members?.length > 0 &&
                  data?.team_members
                    ?.map(item => item.team_member_name)
                    .join(", ")
                }
              />
            )}
            {data?.collaborators?.length > 0 && (
              <PreviewField
                defined={fields?.includes("collaborator_names")}
                title={"Collaborators"}
                value={
                  data?.collaborators?.length > 0 &&
                  data?.collaborators
                    ?.map(item => item.collaborator_name)
                    .join(", ")
                }
              />
            )}
          </Row>
        </Col>
      </Row>
      {data?.project_description && (
        <>
          <div className={`${titleClass} mt-9`}>Description:</div>
          <div className={`whitespace-pre-wrap mt-1 ${valClass}`}>
            {data?.project_description}
          </div>
        </>
      )}
    </>
  )

  const renderProductDetails = (
    <>
      <Row gutter={[15, 15]}>
        <Col xs={24} sm={12}>
          <Row gutter={[8, 8]}>
            <PreviewField
              defined={fields?.includes("product_category_id")}
              title={"Category"}
              value={data?.product_category?.title}
            />
            <PreviewField defined={true} title={"Type"} value={"Product"} />
            <PreviewField
              defined={fields?.includes("manufacture_date")}
              title={"Manufacture"}
              value={data?.manufacture_date?.split(" ")[0]}
            />
            <PreviewField
              defined={fields?.includes("dimension")}
              title={"Dimension"}
              value={data?.dimension}
            />
            <PreviewField
              defined={fields?.includes("available_from")}
              title={"Available from"}
              value={data?.available_from?.split(" ")[0]}
            />
            <PreviewField
              defined={fields?.includes("measure")}
              title={"Measure"}
              value={data?.measure}
            />
            <PreviewField
              defined={fields?.includes("target_audience")}
              title={"Target Audience"}
              value={data?.target_audience}
            />
            <PreviewField
              defined={fields?.includes("read_more_links")}
              title={"More Links"}
              value={data?.read_more_links}
            />
          </Row>
        </Col>
        <Col xs={24} sm={12}>
          <Row gutter={[8, 8]}>
            <PreviewField
              defined={fields?.includes("post_visibility")}
              title={"Post Visibility"}
              value={data?.post_visibility || "-"}
            />
            <PreviewField
              defined={fields?.includes("availability")}
              title={"Availability"}
              value={data?.availability}
            />
            <PreviewField
              defined={fields?.includes("service_area_ids")}
              title={"Service Areas"}
              value={data?.service_areas?.map(item => item.title).join(", ")}
            />
            <PreviewField
              defined={fields?.includes("status")}
              title={"Status"}
              value={data?.status}
            />
            <PreviewField
              defined={fields?.includes("buyers")}
              title={"Client"}
              value={data?.buyers}
            />
            <PreviewField
              defined={fields?.includes("product_materials")}
              title={"Material"}
              value={
                !!data?.product_materials &&
                Array.isArray(data?.product_materials)
                  ? data?.product_materials.map(item => item?.title).join(", ")
                  : undefined
              }
            />
            <PreviewField
              defined={fields?.includes("collaborators")}
              title={"Collaborators"}
              value={
                !!data?.collaborators && Array.isArray(data?.collaborators)
                  ? data?.collaborators
                      .map(item => item?.collaborator_name)
                      .join(", ")
                  : undefined
              }
            />
            <PreviewField
              defined={fields?.includes("team_members")}
              title={"Team"}
              value={
                !!data?.team_members && Array.isArray(data?.team_members)
                  ? data?.team_members
                      .map(item => item?.team_member_name)
                      .join(", ")
                  : undefined
              }
            />
          </Row>
        </Col>
      </Row>

      {data?.description && (
        <>
          <div className={`${titleClass} mt-9`}>Description:</div>
          <div className={`whitespace-pre-wrap mt-1 ${valClass}`}>
            {data?.description}
          </div>
        </>
      )}
    </>
  )

  const onChange = a => {
    setActiveSlide(a + 1)
  }

  useEffect(() => {
    setActiveSlide(1)
    setShowPreview(false)
  }, [])

  return (
    <Carousel
      dots={false}
      prevArrow={<LeftOutlined />}
      nextArrow={<RightOutlined />}
      arrows={data?.images?.length > 1}
      className="overflow-hidden relative"
      afterChange={onChange}
      infinite={false}
      beforeChange={() => setShowPreview(false)}
    >
      {data?.images?.map((item, index) => (
        <div
          key={index}
          className="relative w-full border border-gray-300 rounded-lg overflow-hidden bg-white"
        >
          <Image
            key={index}
            preview={false}
            className="relative w-full"
            src={item.url || item}
            alt={data.title}
            style={{ minHeight: 200 }}
            wrapperClassName="w-full"
          />

          <div
            style={showPreview ? { height: "auto" } : { height: 50 }}
            className={`px-12 bg-gray-600 backdrop-filter backdrop-blur-sm bg-opacity-70 text-white w-full f-14 absolute bottom-0 ${
              showPreview ? "overflow-auto pt-4 pb-8" : "overflow-hidden py-1"
            }`}
          >
            <div
              className="flex items-center justify-between py-2 mb-10"
              onClick={() => setShowPreview(!showPreview)}
            >
              <div className="w-2/5 truncate f-20">
                {showPreview && (data.title || "-")}
              </div>
              <div
                className={`w-1/5 text-center ${
                  showPreview
                    ? "icon-ic_keyboard_arrow_down_24px"
                    : "icon-ic_keyboard_arrow_up_24px"
                } cursor-pointer f-14`}
              />
              <div className="text-right w-2/5 f-18">
                {data?.images?.length > 1 && (
                  <>
                    {activeSlide}/{data?.images?.length}
                  </>
                )}
              </div>
            </div>
            {activeSlide === 1 && type === "product"
              ? renderProductDetails
              : renderProjectDetails}

            {item?.caption && (
              <>
                <div className={`${titleClass} mt-9`}>Caption:</div>
                <div className={"whitespace-pre-wrap mt-1"}>
                  {item?.caption}
                </div>
              </>
            )}
          </div>
        </div>
      ))}
    </Carousel>
  )
}
