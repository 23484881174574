export default function Cover({
  img = "",
  height = 200,
  allowOverflow = false,
  className = "",
  children,
}) {
  return (
    <div
      style={{
        height,
        backgroundImage: `url(${
          img || "/assets/onBoarding/profile_cover.jpg"
        })`,
      }}
      className={`${!allowOverflow && "overflow-hidden"} ${
        className ? className : `${className} mb-4 w-full h-36 sm:h-72`
      } bg-no-repeat bg-cover bg-center border bg-gray-100 rounded-b-lg`}
    >
      {children}
    </div>
  )
}
